import React, { Component }  from 'react';
import { Root, Routes } from 'react-static'
import {isMobile} from 'react-device-detect'
// import Chatbox from './components/ChatBox/ChatBox'

import './app.css'

export default class App extends Component {
  constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 10); // simulates loading of data
  }
  render() {

    const { loading } = this.state;
    
    if(loading) { // if your component doesn't have to wait for async data, remove this block 

      return null; // render null when app is not ready
    }

    return (

    <Root id='container'
    > 
   
      {/* <Chatbox /> */}
      
      <main id="page-wrap">
        <div id="content" >
          <Routes />
        </div>  
      </main>
    </Root>
      )
  }
}


